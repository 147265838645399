import * as React from "react";

export const getDisplayName = (Component: React.ElementType): string => {
  if (typeof Component === "string") {
    return Component;
  }

  if (!Component) {
    return undefined as any;
  }

  return Component.displayName || Component.name || "Component";
};
