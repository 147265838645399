import * as React from "react";

import { withStyleProps, type OutputProps } from "Utils/styleProps";

const makeElement = <T extends keyof JSX.IntrinsicElements>(component: T): React.FunctionComponent<OutputProps<JSX.IntrinsicElements[T]>> =>
  withStyleProps(component);

export const Div = makeElement("div");
export const Hr = makeElement("hr");
export const Ol = makeElement("ol");
export const Span = makeElement("span");
export const Ul = makeElement("ul");
export const Li = makeElement("li");
export const Label = makeElement("label");
export const Table = makeElement("table");
export const Td = makeElement("td");
export const Nav = makeElement("nav");
