export const baseURL = "/employer-application";

// Transform a route into an array of routes, to handle the various ways we use the EmployeeApplication
// Convert this to a nested route in v6
// Multiple path support for <Route /> will go away in v6, hooks will be used instead.
export const nestedPrefixRoutes = (url) => [url, `/company/:companyIDToken${url}`, `/:partner/router${url}`, `/:partner/router/company/:companyIDToken${url}`];

// APIs
export const api = {
  // Company Profile State for Store
  // Used in ER and ER Renewal apps' Sagas to populate Redux Store.
  companyProfile: "/company_profile",

  // ApplicationStatus
  checkApplicationStatus: `${baseURL}/ajax-check-application-status`,

  // Employee CRUD.
  addEmployee: `${baseURL}/ajax-add-employee`,
  deleteEmployee: `${baseURL}/ajax-remove-employee`,
  updateEmployee: `${baseURL}/ajax-update-employee`,
  updateEmployeeContributions: `${baseURL}/ajax-update-employee-contributions`,
  setCoverageExceptions: `${baseURL}/ajax-set-exceptions`,
  setAddEmployeesStepComplete: `${baseURL}/ajax-set-add-employees-step-complete`,

  // Send application to employees.
  sendEmployeeApplications: `${baseURL}/review-and-send-application-links`,

  // Application Menu State for Store (Documents Page)
  documentsMenuState: `${baseURL}/ajax-documents-menu-state`,

  // Upload documents POST url. This is the same url to get.
  // Only used in Zero Ruby.
  documentsUpload: `${baseURL}/ajax-upload-documents`,
  wageDocumentsUpload: `${baseURL}/ajax-upload-wage-documents`,
  wageDocumentValidate: `${baseURL}/ajax-validate-wage-document`,
  // Save payment information.
  savePayment: `${baseURL}/ajax-save-payment`,

  // Employee type overrides
  enableOverrideEmployeeType: `${baseURL}/enable-override-employee-type`,
  disableOverrideEmployeeType: `${baseURL}/disable-override-employee-type`,
};

// Routes
// - Company Info
export const companyInfoURL = `${baseURL}/company-info`;
export const coverageOptionsURL = `${baseURL}/coverage-options`;
export const companyInformationURL = `${baseURL}/company-information`;
export const createAccountURL = `${baseURL}/create-account`;
// - AddEmployees App
export const addEmployeesURL = `${baseURL}/add-employees`;
export const selectContributionTiersURL = `${baseURL}/select-contribution-tiers`;
export const reviewEmployeeEmailsURL = `${baseURL}/send-employee-emails`;
export const reviewEmployeeApplicationStatusURL = `${baseURL}/employee-application-status`;
// - Documents App
export const documentsUploadPageURL = `${baseURL}/documents/upload`;
// - Payment App
export const paymentURL = `${baseURL}/payment`;
// - Done App
export const applicationReadyURL = `${baseURL}/done`;

// Single Page Employer Application specific urls
export const overviewURL = `${baseURL}/overview`;
export const finalizeQuoteURL = `${baseURL}/finalize-quote`;

export const isAnEmployerApplicationPage = () => window.location.pathname.indexOf(baseURL) !== -1;
