import React, { createContext, useContext, Children, cloneElement } from "react";

import classnames from "classnames";
import isIntuit from "Intuit/isIntuit";

export type Theme = {
  classic: boolean;
  intuit: boolean;
  cobalt: boolean;
  tailwind: boolean;
  children: React.ReactNode;
};

const initialState = {
  classic: true,
  intuit: true,
  cobalt: false,
  tailwind: false,
};

const ThemeContext = createContext(initialState);

export const ThemeProvider = ({ children, ...initialTheme }: Theme) => {
  // Set intuit dynamically based on user location
  // Note: Intuit users only interact with /intuit pages
  // so that function always returns the same value and only needs to be called once
  const theme = {
    ...initialState,
    intuit: isIntuit(),
    ...initialTheme,
  };

  /**
   * Enhance children with className "tailwind" if theme.tailwind is true
   * @see ./tailwind.config.js
   */
  const enhancedChildren = theme.tailwind
    ? Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return cloneElement(child, { className: classnames("tailwind", child.props?.className) });
        }
        return child;
      })
    : children;

  return <ThemeContext.Provider value={theme}>{enhancedChildren}</ThemeContext.Provider>;
};

export const useThemeContext = () => useContext(ThemeContext);

export default ThemeContext;
