// Grab the company token from the url
const companyToken = (): string | null | undefined => {
  const pathTokens = window.location.pathname.split("/");
  const idTokenIndex = pathTokens.indexOf("company");
  const idToken = idTokenIndex != -1 ? pathTokens[idTokenIndex + 1] : null;
  return idToken;
};
export default companyToken;

// Output a pathname for company token. Token is automatically shortened to 16 characters.
export const companyTokenString = (idToken: string | null | undefined): string => {
  if (idToken) {
    return `/company/${idToken.slice(0, 16)}`;
  }
  const companyTokenFromURL = companyToken();
  if (companyTokenFromURL) {
    return `/company/${companyTokenFromURL.slice(0, 16)}`;
  }
  return "";
};
