import * as React from "react";

import isIntuit from "Intuit/isIntuit";
import { isTrowserRollout } from "Intuit/TrowserUtils";
import { getDisplayName } from "Utils/react";

import { useThemeContext } from "Theme/Context";

// The increment for margin / padding values
const GRID_SPACE = 8;
const spacers: (number | string)[] = [0, GRID_SPACE * 0.25, GRID_SPACE * 0.5, GRID_SPACE, GRID_SPACE * 2, GRID_SPACE * 3, GRID_SPACE * 4];
spacers.auto = "auto";

export type Size = 0 | 1 | 2 | 3 | 4 | 5 | 6;

// Props relating to margin and padding
export interface FullSpacingProps {
  m: Size;
  mx: Size | "auto";
  my: Size | "auto";
  smm: Size;
  smmx: Size | "auto";
  smmy: Size | "auto";
  mdm: Size;
  mdmx: Size | "auto";
  mdmy: Size | "auto";
  lgm: Size;
  lgmx: Size | "auto";
  lgmy: Size | "auto";
  p: Size;
  px: Size;
  py: Size;
  smp: Size;
  smpx: Size;
  smpy: Size;
  mdp: Size;
  mdpx: Size;
  mdpy: Size;
  lgp: Size;
  lgpx: Size;
  lgpy: Size;
  mt: Size;
  mb: Size;
  ml: Size;
  mr: Size;
  smmt: Size;
  smmb: Size;
  smml: Size;
  smmr: Size;
  mdmt: Size;
  mdmb: Size;
  mdml: Size;
  mdmr: Size;
  lgmt: Size;
  lgmb: Size;
  lgml: Size;
  lgmr: Size;
  pt: Size;
  pb: Size;
  pl: Size;
  pr: Size;
  smpt: Size;
  smpb: Size;
  smpl: Size;
  smpr: Size;
  mdpt: Size;
  mdpb: Size;
  mdpl: Size;
  mdpr: Size;
  lgpt: Size;
  lgpb: Size;
  lgpl: Size;
  lgpr: Size;
}

export interface SpacingProps extends Partial<FullSpacingProps> {}

export interface StyleProps extends SpacingProps {}

export interface OutputProps<T> extends React.HTMLAttributes<T>, StyleProps {}

// This helper method standardizes commonly available Bootstrap styles on
// arbitrary React components.
const styleProps = <T extends {}>(_props: OutputProps<T>): React.HTMLAttributes<T> => {
  const props: any = _props;
  const result = { ...props };
  const classNames: string[] = [];
  if ("className" in result) {
    classNames.push(result.className);
  }
  ["m", "p"].forEach((propertyKey) => {
    ["", "sm", "md", "lg"].forEach((mediaKey) => {
      ["", "x", "y", "t", "b", "l", "r"].forEach((sideKey) => {
        const key = `${mediaKey}${propertyKey}${sideKey}`;
        if (key in props) {
          if (typeof props[key] !== "undefined") {
            classNames.push(`${key}-${props[key]}${isIntuit() && isTrowserRollout() ? "--intuit" : ""}`);
          }
          delete result[key];
        }
      });
    });
  });
  result.className = classNames.join(" ");
  return result;
};

// This decorator will apply the styleProps and pass the remainder on to the
// wrapped component.
export const withStyleProps = <T extends React.ElementType>(
  Component: T,
  defaultProps?: React.ComponentProps<T> & StyleProps,
): React.FunctionComponent<OutputProps<React.ComponentProps<T>>> => {
  const WrappedComponent: React.FunctionComponent<React.ComponentProps<T> & StyleProps> = (props) => {
    const { cobalt, intuit } = useThemeContext();

    let themeProps = {};
    if (intuit && isIntuit()) {
      themeProps = { ...props };
    } else if (cobalt) {
      themeProps = { ...props };
    }

    const allProps = {
      ...(defaultProps ?? {}),
      ...themeProps,
      ...props,
    };

    return <Component {...styleProps(allProps)} />;
  };
  WrappedComponent.displayName = `StyleProps(${getDisplayName(Component)})`;
  return WrappedComponent;
};

export default styleProps;
